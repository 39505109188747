window.addEventListener('DOMContentLoaded', () => {
  if (window.Geenie?.VwoEvent && typeof window.Geenie.VwoEvent === 'object') {
    if ('clickElement' in window.Geenie.VwoEvent) {
      // const investBtnLabel = { module: 'right_content', parts: 'investment_button' };
      // const viewReturnBtnLabel = { module: 'hero', parts: 'investment_button' };

      /** A list of parameters for window.Geenie.VwoEvent.clickElement.setHandlersToPushEvent */
      const clickEventParams = [];

      clickEventParams.forEach(([campaignKey, dataClickTrackingLabels]) => {
        window.Geenie.VwoEvent.clickElement.setHandlersToPushEvent(
          campaignKey,
          dataClickTrackingLabels,
        );
      });
    }

    if ('visitPage' in window.Geenie.VwoEvent) {
      const campaignKeysForVisitPage = [];

      campaignKeysForVisitPage.forEach(campaignKey => {
        window.Geenie.VwoEvent.visitPage.push(campaignKey, 'pc', 'project');
      });
    }
  }
});
